<script lang="ts" setup>
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue'
import giftlyLogo from '@consumer/assets/images/giftly-logo.svg'
import MobileSidebarLink from '@consumer/modules/layout/MobileSidebarLink.vue'
import { useSupport } from '@consumer/composables/support'
import { support } from '@consumer/api'

defineProps<{
  open: boolean
}>()

const { listCategories } = useSupport()

const emit = defineEmits(['close'])

const closeButton = ref<HTMLButtonElement>()

function onClose () {
  emit('close')
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-50000 lg:hidden justify-end"
      :initialFocus="closeButton"
      @close="onClose"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0"/>
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div class="relative flex flex-col w-full h-screen bg-white text-16px mobile-sidebar">
          <button
            ref="closeButton"
            type="button"
            class="absolute right-4 p-5 outline-none ring-0 ring-0:active z-1000"
            style="top: 22px;"
            @click="onClose"
          >
            <span class="sr-only">Close sidebar</span>
            <GIcon name="close" size="12px" color="grey-700"/>
          </button>
          <div class="bg-white h-screen flex flex-col">
            <div class="w-full px-8 py-4 flex-1">
              <GLink href="/" class="block py-5" style="margin-bottom: 18px;">
                <img width="65" height="30" :src="giftlyLogo" alt="Giftly">
              </GLink>

              <div class="mobile-main-links">
                <MobileSidebarLink
                  v-for="category, i in listCategories"
                  :key="i"
                  :href="support.category.path({ category: category.name })"
                  inertiaLink
                  @click="onClose"
                >
                  {{ category.label }}
                </MobileSidebarLink>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
