import type { SupportCategory } from '@consumer/types/SupportArticle'
import { usePage, useForm } from '@inertiajs/vue3'

export { useForm }

export function useSupport () {
  const page = usePage<{
    supportCategories: SupportCategory[]
    currentCategory?: SupportCategory
  }>()

  return {
    supportCategories: computed(() => page.props.supportCategories),
    listCategories: computed(() => page.props.supportCategories.filter(c => !c.hideFromList)),
    currentCategory: computed(() => page.props.currentCategory),
  }
}
