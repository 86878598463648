<script lang="ts" setup>import { ref as _ref } from 'vue';

import giftlyLogo from '@consumer/assets/images/giftly-g-logo.svg'
import SupportMobileSidebar from '@consumer/modules/layout/SupportMobileSidebar.vue'
import SupportSubNav from '@consumer/modules/layout/SupportSubNav.vue'
import { useSupport } from '@consumer/composables/support'
import { support } from '@consumer/api'

const { listCategories } = useSupport()

let sidebarOpen = _ref(false)

function onOpenSidebar () {
  sidebarOpen.value = true
}
function onCloseSidebar () {
  sidebarOpen.value = false
}
</script>

<template>
  <SupportMobileSidebar :open="sidebarOpen" @close="onCloseSidebar"/>
  <nav class="bg-white rounded">
    <SupportSubNav/>
    <div id="header" class="relative">
      <GContainer class="h-65px lg:h-85px !px-6px !pl-4 !py-10px flex items-center justify-between font-normal">
        <div
          class="giftly-logo w-260px bg-contain flex items-center h-full"
        >
          <GLink href="/">
            <img
              width="65"
              height="26"
              class="w-6 h-10 lg:w-6 lg:h-10"
              :src="giftlyLogo"
              alt="Giftly"
            >
          </GLink>
          <GDivider vertical class="h-6 mx-4" color="navy"/>
          <GLink
            :href="support.index.path()"
            class="top-nav-link !mx-0"
          >
            Help Center
          </GLink>
        </div>
        <div class="flex justify-between items-center h-full">
          <ul class="hidden lg:flex text-16px h-full lg:flex-row gap-16px items-center">
            <li v-for="category, i in listCategories" :key="i">
              <GLink class="top-nav-link" :href="support.category.path({ category: category.name })">
                {{ category.label }}
              </GLink>
            </li>
          </ul>

          <div id="header-mobile-menu" class="lg:hidden block w-auto text-14px">
            <ul class="flex flex-row gap-10px">
              <li class="flex items-center">
                <button
                  ref="hamburgerMenuBtn"
                  class="p-2 lg:hidden hover:bg-gray-100"
                  :class="{ 'opacity-0': sidebarOpen }"
                  @click="onOpenSidebar"
                >
                  <span class="sr-only">Open main menu</span>
                  <GIcon name="menu"/>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </GContainer>
    </div>
  </nav>
</template>

<style scoped lang="scss">
a.top-nav-link {
  color: inherit;
}
</style>
