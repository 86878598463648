<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useSharedData } from '@consumer/composables/sharedData'
import { myAccount, support } from '@consumer/api'

const __$temp_1 = (useSharedData()),
  currentUser = _toRef(__$temp_1, 'currentUser');
</script>

<template>
  <div class="hidden lg:block h-34px bg-[#eee] top-sub-nav relative">
    <GContainer class="h-full text-14px flex items-center justify-end gap-30px !pr-30px py-7px relative">
      <GLink href="/" alt="Giftly Homepage">Giftly Homepage</GLink>

      <template v-if="!currentUser?.corporate">
        <a href="/entercode" alt="Redeem">Redeem</a>
        <a href="/corp" alt="Corporate">Corporate</a>
        <GLink :href="support.index.path()" alt="Help">Help</GLink>
      </template>

      <template v-if="currentUser">
        <template v-if="currentUser.corporate">
          <a href="/corp/dashboard" alt="Corporate Dashboard">Corporate Dashboard</a>
        </template>
        <template v-else>
          <GLink :href="myAccount.purchases.path()" alt="My Gifts">My Gifts</GLink>
          <Popover v-slot="{ open }" class="hidden lg:block">
            <PopoverButton as="a" class="cursor-pointer">
              Hi {{ currentUser.name }}!
              <GIcon
                :name="open ? 'arrow-up' :'arrow-down'"
                class="absolute stroke-grey-500 fill-grey-500"
                style=" height: 15px; right: 8px; top: 10px;width: 10px;"
              />
            </PopoverButton>
            <transition
              enterActiveClass="transition duration-200 ease-out"
              enterFromClass="translate-y-1 opacity-0"
              enterToClass="translate-y-0 opacity-100"
              leaveActiveClass="transition duration-150 ease-in"
              leaveFromClass="translate-y-0 opacity-100"
              leaveToClass="translate-y-1 opacity-0"
            >
              <PopoverPanel
                id="logged-in-dropdown"
                class="
              absolute top-34px right-0  bg-white z-1000 w-full shadow-sm
              flex flex-col
              rounded-lg w-235px p-10px pb-20px bg-white space-y-12px
              text-16px
            "
              >
                <GIcon
                  name="arrow-up"
                  class="absolute !w-15px !h-20px right-15px -top-28px stroke-grey-400 fill-grey-400"
                />
                <GLink :href="myAccount.profile.path()">My Account</GLink>
                <a href="/signout">Sign Out</a>
              </PopoverPanel>
            </transition>
          </Popover>

          <a href="/signout" class="lg:hidden">Log out</a>
        </template>
      </template>
      <template v-else>
        <a href="/login" alt="Login">Login</a>
      </template>
    </GContainer>
  </div>
</template>

<style lang="scss" scoped>
a {
  color: #111e36 !important;
  text-decoration: none;
}
</style>
